import "~/unpoly"
import "~/alpine"
import "~/helpscout"

up.compiler("#stripe-checkout[data-compiler]", async function(el) {
  const stripe = window.Stripe(el.dataset.pk)

  const checkout = await stripe.initEmbeddedCheckout({
    fetchClientSecret: function() {
      return el.dataset.cid
    }
  })

  up.destructor(el, function() {
    checkout.destroy()
  })

  checkout.mount(el)
})
